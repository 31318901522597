import React from 'react';
import PageComponent from './PageComponent';
import Layout from '../components/Layout';
import LoginComponent from '../components/LoginComponent';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserActions from '../redux/UserActions';
import { withTranslation } from 'utils/with-i18next';
import Loading from '../components/Common/Loading';
import NextHead from 'next/head';

class Login extends PageComponent {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    super.componentDidMount();
    this.setState({ loading: false });
  }

  render() {
    const { t } = this.props;

    return (
      <Layout>
        <NextHead>
          <title>BGMs - 로그인</title>
          <meta
            name="description"
            content="BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요. "
          />
          <meta property="og:title" content="BGMs - 로그인" />
          <meta name="subject" content="BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요." />
          <meta
            property="og:description"
            content="BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요."
          />
          <meta property="og:url" content="https://bgms.live/login" />

          {/* <!-- twitter meta --> */}
          <meta
            name="twitter:image"
            content="https://bgms-bucket.s3.ap-northeast-2.amazonaws.com/channel/image/bgmsMetaImg.jpg"
          />
          <meta name="twitter:domain" content="https://bgms.live/login" />

          <meta name="twitter:title" content="BGMs - 로그인" />
          <meta
            name="twitter:description"
            content="BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요."
          />
        </NextHead>
        {this.state.loading ? (
          <Loading />
        ) : (
          <div className="login">
            <div className="wrapB">
              <h2>
                {/* Login */}
                {t('h2')}
              </h2>
              <LoginComponent t={t} />
              <p>
                {t('desc.1')}&nbsp;
                <br />
                <a id="policy-1" href="/policy/privacy">
                  {t('desc.privacyPolicy')}
                </a>
                {t('desc.and')}&nbsp;
                <a id="policy-2" href="/policy/service">
                  {t('desc.termsOfUse')}
                </a>
                {t('desc.2')}
              </p>
            </div>
          </div>
        )}
      </Layout>
    );
  }
}

Login.propTypes = {
  UserRedux: PropTypes.object,
};

export default connect(
  state => state,
  (dispatch, ownProps) => {
    return { ...UserActions(dispatch, ownProps) };
  }
)(withTranslation('login')(Login));
