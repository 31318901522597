import React from 'react';

class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: !props.width ? 16 : props.width,
      height: !props.height ? 16.262 : props.height,
      color: !props.color ? '#fafafa' : props.color,
    };
  }

  render() {
    return (
      <svg
        className={this.props.className}
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox={`0 0 ${this.state.width} ${this.state.height}`}>
        <path
          id="google-brands"
          d="M16,16.321a7.551,7.551,0,0,1-7.869,7.941A8.131,8.131,0,0,1,8.131,8a7.819,7.819,0,0,1,5.452,2.128L11.37,12.256c-2.9-2.793-8.279-.7-8.279,3.875a5.093,5.093,0,0,0,5.039,5.134,4.4,4.4,0,0,0,4.616-3.5H8.131v-2.8h7.741A7.128,7.128,0,0,1,16,16.321Z"
          transform="translate(0 -8)"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default Logo;
