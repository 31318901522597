import React from 'react';
import BASIC_CONSTANT from '../../constants/basic.constants.js/basic.constants.js.js';
import GoogleIcon from '../../assets/img/google-brands';

class LoginComponent extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <a
        id="btn-login"
        className="btn btn-red btn-login"
        href={`${BASIC_CONSTANT.BASE_URL}${BASIC_CONSTANT.BACKEND_GOOGLE_AUTH_URL}`}>
        <GoogleIcon />
        {t('continueGoogle')}
      </a>
    );
  }
}

export default LoginComponent;
